<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @create="create" @edit="edit"/>
      </v-col>
    </v-row>
    <create-project modal_name="create_project" ref="create_project" @refresh="reload"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import CreateProject from "./Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
export default {
  components: {
    BaseTable,
    BaseBreadcrumb,
    CreateProject,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("projects"),
        name: "projects",
        model: "project",
      },
    };
  },
  methods: {
    create() {
      this.$refs.create_project.load();
    },
    edit(item) {
      this.$router.push("/" + this.page.name + "/" + item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
  },
};
</script>
